import './App.css';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Users from './Users';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar data-bs-theme="dark">
          <Container>
            <Navbar.Brand href="/">
              <img
                src="/agrotutor_logo.png"
                alt="AgroTutor Logo"
                style={{ height: '40px' }}
              />
            </Navbar.Brand>
            <Nav className="me-auto">
              <Nav.Link><Link to="/">Farmer List</Link></Nav.Link>
              <Nav.Link><Link to="/about">Add New Farmer</Link></Nav.Link>
              {/* <Nav.Link><Link to="/users">Users</Link></Nav.Link> */}
            </Nav>

          </Container>
        </Navbar>
        <Routes>
          <Route Component={About} path="about"></Route>
          <Route Component={Users} path="users"></Route>
          <Route Component={Home} path="/"></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
