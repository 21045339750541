import React, { useState, useEffect } from "react";
import { Container, Table, Card } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './App.css';

export default function Home() {
    const [data, setData] = useState([]);
    const [mode, setMode] = useState("Online");

    useEffect(() => {
        const fetchData = async () => {
            try {
                await syncOfflineData();

                let url = "https://services.indevconsultancy.in/pwa_api/download_farmer_data.php";
                const response = await fetch(url);
                const apiData = await response.json();

                // let offlineData = JSON.parse(localStorage.getItem("farmer")) || [];

                // const combinedData = [...offlineData, ...apiData];

                // setData(combinedData);
                // localStorage.setItem("farmer", JSON.stringify(combinedData));
                let collectionData = localStorage.getItem("farmer");
                if (collectionData) {
                    setData(JSON.parse(collectionData));
                    // setMode("Offline");
                }
            } catch (error) {
                let collectionData = localStorage.getItem("farmer");
                if (collectionData) {
                    setData(JSON.parse(collectionData));
                    // setMode("Offline");
                }
            }
        };


        const syncOfflineData = async () => {
            var data = {};
            let offlineData = JSON.parse(localStorage.getItem("farmer"));
            let unsyncedData = offlineData.filter(item => item.synced === "0");
            if (unsyncedData.length > 0) {
                try {

                    data['farmer_json_data'] = unsyncedData;
                    console.log(JSON.stringify(data));
                    const url = "https://services.indevconsultancy.in/pwa_api/inser_farmer_data_json.php";
                    const response = await fetch(url, {
                        mode: 'no-cors',
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(data),
                    });
                    console.log("dataresponce", response)
                    if (!response.ok) {
                        console.log("Data uploaded");
                        unsyncedData.forEach(item => item.synced = 1);

                        localStorage.setItem("farmer", JSON.stringify(offlineData));
                    }
                } catch (error) {
                    console.error("Failed to sync offline data:", error);
                }
            }
        };

        if (!navigator.onLine) {
            let collectionData = localStorage.getItem("farmer");
            if (collectionData) {
                setData(JSON.parse(collectionData));
                setMode("Offline");
            }
        } else {
            fetchData();
        }

    }, []);

    return (
        <div>
            <div style={{ borderBottom: "3px solid #74bd43" }}></div>
            <Container>
                <div>
                    {/* {
                        mode === "Offline" ?
                            <div className="alert alert-info text-center" role="alert">
                                <b>Highly suitable for your condition (You are in Offline mode)</b>
                            </div> :
                            <div className="alert alert-info text-center" role="alert">
                                <b>Highly suitable for your condition (You are in Online mode)</b>
                            </div>
                    } */}
                </div>
                <Table responsive="sm">
                    <Row className="py-2">

                        {
                            data.map((farmerData, index) => {
                                // const farmerData = JSON.parse(item.farmer_json_data);
                                let recommendations = {};
                                let hedingtest = {};

                                if (farmerData.agroClimaticZone == 'Lakeshore' && farmerData.soilTexture == 'Clayey') {
                                    hedingtest = {
                                        testlableHedind: ['Land Preparation'],
                                        Choice_of_Combinations: [''],
                                        Choice_of_Carop: ['Choice of Doubled-up Legume Rotation (DLR) Crops'],
                                        NutrientManagement: ['Nutrient Management'],
                                        Agroforestry: ['Agroforestry'],

                                        notSuitable_testlableHedind: [''],
                                        notSuitable_Choice_of_Combinations: ['Choice of planting density and Combinations'],
                                        notSuitable_Choice_of_Carop: [''],
                                        notSuitable_NutrientManagement: [''],
                                        notSuitable_Agroforestry: [''],


                                    };
                                    recommendations = {
                                        suitable: {

                                            landPreparation: ['Ripping', 'Permanently raised beds'],
                                            dLRCombinations: [],
                                            dLRCrops: [
                                                'Doubled-up legume rotation: Pigeon pea + soybean ',
                                                'Doubled-up legume rotation: Pigeon pea + cowpea',
                                                'Doubled-up legume rotation: Pigeon pea + groundnut'
                                            ],
                                            nutrientManagement: [
                                                'Inorganic Fertilizer - NPK & Urea Mulching',
                                                'Livestock Manure: High quality manure (poultry/pig)',
                                                'Combined Fertilizer: Low quality manure + N fertilizer',
                                                'Combined Fertilizer: High quality manure + N fertilizer'
                                            ],
                                            agroforestry: ['Gliricidia-maize intercropping']
                                        },

                                        notSuitable: {

                                            landPreparation1: [],
                                            ontsuitabledLRCombination: [
                                                'Planting Density: Low maize density',
                                                'Planting Density: Low legume density',
                                                'Planting Density: High legume density'
                                            ],
                                            notdLRCrops: [

                                            ],
                                            notnutrientManagement: [
                                            ],
                                            notagroforestry: []
                                        }
                                    };
                                } else if (farmerData.agroClimaticZone == 'Lakeshore' && farmerData.soilTexture == 'Sandy') {
                                    hedingtest = {
                                        testlableHedind: ['Land Preparation'],
                                        Choice_of_Combinations: [''],
                                        Choice_of_Carop: ['Choice of Doubled-up Legume Rotation (DLR) Crops'],
                                        NutrientManagement: ['Nutrient Management'],
                                        Agroforestry: ['Agroforestry'],

                                        notSuitable_testlableHedind: ['Land Preparation'],
                                        notSuitable_Choice_of_Combinations: ['Choice of planting density and Combinations'],
                                        notSuitable_Choice_of_Carop: [''],
                                        notSuitable_NutrientManagement: ['Nutrient Management'],
                                        notSuitable_Agroforestry: ['Agroforestry'],
                                    };
                                    recommendations = {
                                        suitable: {

                                            landPreparation: ['Conservation Agriculture', 'Ripping', 'Tied ridges'],
                                            dLRCombinations: [],

                                            dLRCrops: [
                                                'Doubled-up legume rotation: Pigeon pea + soybean',
                                                'Doubled-up legume rotation: Pigeon pea + cowpea',
                                                'Doubled-up legume rotation: Pigeon pea + groundnut'
                                            ],
                                            nutrientManagement: [
                                                'Livestock Manure: High quality manure (poultry/pig)',
                                                'Combined Fertilizer: Low quality manure + N fertilizer',
                                                'Combined Fertilizer: High quality manure + N fertilizer',
                                                ' Nutrient Management: Inorganic Fertilizer - NPK & Urea',
                                            ],
                                            agroforestry: ['Gliricidia-maize intercropping']
                                        },

                                        notSuitable: {

                                            landPreparation1: ['Permanently raised beds '],
                                            ontsuitabledLRCombination: [
                                                'Planting Density: Low maize density',
                                                'Planting Density: Low legume density',
                                                'Planting Density: High legume density'
                                            ],
                                            notdLRCrops: [

                                            ],
                                            notnutrientManagement: [
                                                '  Combined Fertilizer: Green manuring legumes'
                                            ],
                                            notagroforestry: ['Biomass transfer']
                                        }
                                    };
                                } else if (farmerData.agroClimaticZone == 'Mid-Altitude' && farmerData.soilTexture == 'Clayey') {
                                    hedingtest = {
                                        testlableHedind: ['Land Preparation'],
                                        Choice_of_Combinations: [''],
                                        Choice_of_Carop: ['Choice of Doubled-up Legume Rotation (DLR) Crops'],
                                        NutrientManagement: ['Nutrient Management'],
                                        Agroforestry: ['Agroforestry'],

                                        notSuitable_testlableHedind: ['Land Preparation'],
                                        notSuitable_Choice_of_Combinations: ['Choice of planting density and Combinations'],
                                        notSuitable_Choice_of_Carop: [''],
                                        notSuitable_NutrientManagement: [''],
                                        notSuitable_Agroforestry: [''],
                                    };

                                    recommendations = {
                                        suitable: {
                                            landPreparation: ['Ripping', 'Permanently raised beds'],
                                            dLRCombinations: [],

                                            dLRCrops: [
                                                'Doubled-up legume rotation: Pigeon pea + cowpea',
                                                'Doubled-up legume rotation: Pigeon pea + groundnut'
                                            ],
                                            nutrientManagement: [
                                                'Nutrient Management: Inorganic Fertilizer - NPK & Urea',
                                                ' Combined Fertilizer: High quality manure + N fertilizer',
                                                'Livestock Manure: High quality manure (poultry/pig)',
                                                ' Combined Fertilizer: Green manuring legumes ',
                                            ],
                                            agroforestry: ['Gliricidia-maize intercropping']
                                        },

                                        notSuitable: {

                                            landPreparation1: ['Tied ridges'],
                                            ontsuitabledLRCombination: [
                                                'Planting Density: Low maize density',
                                                'Planting Density: Low legume density',
                                            ],
                                            notdLRCrops: [

                                            ],
                                            notnutrientManagement: [
                                            ],
                                            notagroforestry: []
                                        }
                                    };
                                } else if (farmerData.agroClimaticZone == 'Mid-Altitude' && farmerData.soilTexture == 'Sandy') {
                                    hedingtest = {
                                        testlableHedind: ['Land Preparation'],
                                        Choice_of_Combinations: [''],
                                        Choice_of_Carop: ['Choice of Doubled-up Legume Rotation (DLR) Crops'],
                                        NutrientManagement: ['Nutrient Management'],
                                        Agroforestry: ['Agroforestry'],

                                        notSuitable_testlableHedind: ['Land Preparation'],
                                        notSuitable_Choice_of_Combinations: ['Choice of planting density and Combinations'],
                                        notSuitable_Choice_of_Carop: [''],
                                        notSuitable_NutrientManagement: [''],
                                        notSuitable_Agroforestry: ['Agroforestry'],
                                    };
                                    recommendations = {
                                        suitable: {

                                            landPreparation: ['Conservation Agriculture', 'Ripping'],
                                            dLRCombinations: [],
                                            dLRCrops: [
                                                'Doubled-up legume rotation: Pigeon pea + groundnut',
                                                'Doubled-up legume rotation: Pigeon pea + cowpea'
                                            ],
                                            nutrientManagement: [
                                                'Nutrient Management: Inorganic Fertilizer - NPK & Urea',
                                                'Livestock Manure: High quality manure (poultry/pig)',
                                                ' Combined Fertilizer: High quality manure + N fertilizer',
                                                ' Combined Fertilizer: Green manuring legumes ',
                                            ],
                                            agroforestry: ['Gliricidia-maize intercropping']
                                        },

                                        notSuitable: {

                                            landPreparation1: ['Permanently raised beds '],
                                            ontsuitabledLRCombination: [
                                                'Planting Density: Low maize density',
                                                'Planting Density: Low legume density',
                                                'Planting Density: High legume density'
                                            ],
                                            notdLRCrops: [

                                            ],
                                            notnutrientManagement: [

                                            ],
                                            notagroforestry: ['Biomass transfer ']
                                        }
                                    };
                                } else if (farmerData.agroClimaticZone == 'High-Altitude' && farmerData.soilTexture == 'Clayey') {
                                    hedingtest = {
                                        testlableHedind: ['Land Preparation'],
                                        Choice_of_Combinations: ['Choice of planting density and Combinations'],
                                        Choice_of_Carop: ['Choice of Doubled-up Legume Rotation (DLR) Crops'],
                                        NutrientManagement: ['Nutrient Management'],
                                        Agroforestry: ['Agroforestry'],

                                        notSuitable_testlableHedind: ['Land Preparation'],
                                        notSuitable_Choice_of_Combinations: ['Choice of planting density and Combinations'],
                                        notSuitable_Choice_of_Carop: ['Choice of Doubled-up Legume Rotation (DLR) Crops'],
                                        notSuitable_NutrientManagement: [''],
                                        notSuitable_Agroforestry: [''],
                                    };
                                    recommendations = {
                                        suitable: {

                                            landPreparation2: ['Permanently raised beds'],
                                            dLRCombinations: [
                                                'Planting Density: High maize density',
                                            ],
                                            dLRCrops: [

                                            ],
                                            nutrientManagement: [
                                                'Nutrient Management: Inorganic Fertilizer - NPK & Urea',
                                                'Livestock Manure: High quality manure (poultry/pig)',
                                                ' Combined Fertilizer: High quality manure + N fertilizer',
                                            ],
                                            agroforestry: ['Gliricidia-maize intercropping']
                                        },

                                        notSuitable: {

                                            landPreparation1: ['Conservation Agriculture', 'Tied ridges', 'Planting basins'],
                                            ontsuitabledLRCombination: [
                                                'Planting Density: Low maize density',
                                                'Planting Density: Low legume density',
                                            ],
                                            notdLRCrops: [
                                                ' Doubled-up legume rotation: Pigeon pea + cowpea'
                                            ],

                                            notnutrientManagement: [

                                            ],
                                            notagroforestry: []
                                        }
                                    };
                                } else if (farmerData.agroClimaticZone == 'High-Altitude' && farmerData.soilTexture == 'Clayey') {
                                    hedingtest = {
                                        testlableHedind: ['Land Preparation'],
                                        Choice_of_Combinations: ['Choice of planting density and Combinations'],
                                        Choice_of_Carop: ['Choice of Doubled-up Legume Rotation (DLR) Crops'],
                                        NutrientManagement: ['Nutrient Management'],
                                        Agroforestry: ['Agroforestry'],

                                        notSuitable_testlableHedind: [''],
                                        notSuitable_Choice_of_Combinations: ['Choice of planting density and Combinations'],
                                        notSuitable_Choice_of_Carop: ['Choice of Doubled-up Legume Rotation (DLR) Crops'],
                                        notSuitable_NutrientManagement: ['Nutrient Management'],
                                        notSuitable_Agroforestry: ['Agroforestry'],
                                    };
                                    recommendations = {
                                        suitable: {

                                            landPreparation2: ['Conservation Agriculture'],

                                            dLRCombinations: [
                                                'Planting Density: High maize density',
                                                'Doubled-up legume rotation: Pigeon pea + cowpea'
                                            ],
                                            dLRCrops: [],

                                            nutrientManagement: [
                                                'Nutrient Management: Inorganic Fertilizer - NPK & Urea',
                                                '  Livestock Manure: High quality manure (poultry/pig)',
                                                '  Combined Fertilizer: High quality manure + N fertilizer ',
                                            ],
                                            agroforestry: ['Gliricidia-maize intercropping']
                                        },

                                        notSuitable: {

                                            landPreparation1: [],
                                            ontsuitabledLRCombination: [
                                                'Planting Density: Low maize density',
                                                'Planting Density: Low legume density',
                                            ],
                                            notdLRCrops: [
                                                'Doubled-up legume rotation: Pigeon pea + cowpea',
                                            ],
                                            notnutrientManagement: [
                                                ' Combined Fertilizer: Green manuring legumes'
                                            ],
                                            notagroforestry: ['Biomass transfer']
                                        }
                                    };
                                }
                                return (
                                    <Col sm={4} key={index} className="py-2">
                                        <Card border="secondary" className="p-0">
                                            <Card.Header className="bg-card-body" style={{ backgroundColor: '#b6ddc7' }}>
                                                <Row>
                                                    <Col sm={12}>
                                                        <b>Name</b> : {farmerData.farmerName}
                                                    </Col>
                                                    <Col sm={12}>
                                                        <b>Mobile No</b> :  {farmerData.farmerMobile}
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body className="p-0 m-0 bg-card-body" >
                                                <Card.Text className="px-2">
                                                    <span className="py-2">
                                                        <h5><b>Highly suitable for your condition:</b></h5>
                                                        <p className='p-0 m-0'><b>{hedingtest?.testlableHedind}</b></p>

                                                    </span>
                                                    <span className="py-2">

                                                        {recommendations?.suitable && recommendations?.suitable?.landPreparation?.map((prep, idx) => (
                                                            <p key={idx}>{idx + 1}. {prep}</p>
                                                        ))}

                                                    </span>

                                                    <span className="py-2">
                                                        <p className='p-0 m-0'><b>{hedingtest?.Choice_of_Combinations}</b></p>
                                                    </span>
                                                    <span className="py-2">
                                                        {recommendations?.suitable && recommendations?.suitable?.dLRCombinations?.map((prep5, idx5) => (
                                                            <p key={idx5}>{idx5 + 1}. {prep5}</p>
                                                        ))}
                                                    </span>
                                                    <span className="py-2">
                                                        <p className='p-0 m-0'><b>{hedingtest?.Choice_of_Carop}</b></p>
                                                    </span>
                                                    <span className="py-2">
                                                        {recommendations?.suitable && recommendations?.suitable?.dLRCrops?.map((prep1, idx1) => (
                                                            <p key={idx1}>{idx1 + 1}. {prep1}</p>
                                                        ))}
                                                    </span>

                                                    <span className="py-2">
                                                        <p className='p-0 m-0'><b>{hedingtest?.NutrientManagement}</b></p>
                                                    </span>
                                                    <span className="py-2">
                                                        {recommendations?.suitable && recommendations?.suitable?.nutrientManagement?.map((prep2, idx2) => (
                                                            <p key={idx2}>{idx2 + 1}. {prep2}</p>
                                                        ))}
                                                    </span>
                                                    <span className="py-2">
                                                        <p className='p-0 m-0'><b>{hedingtest?.Agroforestry}</b></p>
                                                    </span>
                                                    <span className="py-2">
                                                        {recommendations?.suitable && recommendations?.suitable?.agroforestry?.map((prep3, idx3) => (
                                                            <p key={idx3}>{idx3 + 1}. {prep3}</p>
                                                        ))}
                                                    </span>

                                                </Card.Text>

                                            </Card.Body>
                                            <hr className='m-0 p-0'></hr>
                                            <Card.Body className="p-0 m-0 card-body-footer">
                                                <Card.Text className="px-2">
                                                    <span className="py-2">
                                                        <h5><b>Not suitable for your condition:</b></h5>
                                                        <p className='p-0 m-0'><b>{hedingtest?.notSuitable_testlableHedind}</b></p>
                                                    </span>

                                                    {recommendations?.notSuitable && recommendations?.notSuitable?.landPreparation1?.map((prep7, idx7) => (
                                                        <p key={idx7}>{idx7 + 1}. {prep7}</p>
                                                    ))}
                                                    <span className="py-2">
                                                        <p className='p-0 m-0'><b>{hedingtest?.notSuitable_Choice_of_Combinations}</b></p>
                                                    </span>

                                                    {recommendations?.notSuitable && recommendations?.notSuitable?.ontsuitabledLRCombination?.map((prep6, idx6) => (
                                                        <p key={idx6}>{idx6 + 1}. {prep6}</p>
                                                    ))}

                                                    <span className="py-2">
                                                        <p className='p-0 m-0'><b>{hedingtest?.notSuitable_Choice_of_Carop}</b></p>
                                                    </span>

                                                    {recommendations?.notSuitable && recommendations?.notSuitable?.notdLRCrops?.map((prep6, idx6) => (
                                                        <p key={idx6}>{idx6 + 1}. {prep6}</p>
                                                    ))}

                                                    <span className="py-2">
                                                        <p className='p-0 m-0'><b>{hedingtest?.notSuitable_NutrientManagement}</b></p>
                                                    </span>

                                                    {recommendations?.notSuitable && recommendations?.notSuitable?.notnutrientManagement?.map((prep6, idx6) => (
                                                        <p key={idx6}>{idx6 + 1}. {prep6}</p>
                                                    ))}

                                                    <span className="py-2">
                                                        <p className='p-0 m-0'><b>{hedingtest?.notSuitable_Agroforestry}</b></p>
                                                    </span>

                                                    {recommendations?.notSuitable && recommendations?.notSuitable?.notagroforestry?.map((prep6, idx6) => (
                                                        <p key={idx6}>{idx6 + 1}. {prep6}</p>
                                                    ))}

                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )

                            })}
                    </Row>

                </Table>
            </Container>
        </div>
    );
}
